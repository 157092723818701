.blue-bar {
  background-color: #0071BC;
  width: 100%;
}

.large-logo{
  max-width: 62px;
  position: relative;
  margin-bottom: -34px;
  margin-top: 10px;
  z-index: 3;
  @media screen and (max-width: 39.9375em) {
    display: none;
  }
}

.title-bar{
  background-color: inherit;
  .menu-button-container{
    width: 100%;
    &:hover{
      cursor: pointer;
    }
  }
}

.top-bar{
  .top-bar-left{
    font-family: "acumin-pro-condensed", sans-serif;
    font-weight: 600;
    font-size: 16px;
    ul{
      li{
        a{
          text-align: left;
          text-transform: uppercase;
          color: #FFFFFF !important;
          &:hover{
            color: #3FA9F5 !important;
          }
        }
        ul{
          li{
            a{
              text-transform: none;
              font-weight: 400;
              &:hover{
                color: #0071BC !important;
              }
            }
            ul{
              li{
                background-color: #0071BC;
                a{
                  text-transform: none;
                  font-weight: 400;
                  &:hover{
                    color: #3FA9F5 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}