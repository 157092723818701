//vars
$border-colour: #E5EBED;

body {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  //background-image: url("../img/background-images/AdobeStock_50698443-min.jpeg") !important;
  //background-image: url("../img/background-images/AdobeStock_168056836-min.jpeg") !important;
}

//fonts

h1 {
}

h4 {
  color: #0071BC;
}

p {
  color: #999999;
}

label {
  font-family: "acumin-pro-condensed", sans-serif;
}

//navigation

@import 'components/navigation';

//footer

@import 'components/footer';

//sidebar

@import 'components/sidebar';

//all pages

.blue-block {
  height: 300px;
  background-color: #0071BC;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -999;
}

.white-background {
  background-color: #FFFFFF;
  border: solid 1px $border-colour;
  @media screen and (max-width: 63.9375em) {
    border-left: solid 0 $border-colour;
    border-right: solid 0 $border-colour;

  }

}

.grey-background {
  background-color: #B3B3B3;
  border-top: solid 1px $border-colour;
}

.box {
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (max-width: 63.9375em) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media screen and (max-width: 39.9375em) {
    padding-left: 0;
    padding-right: 0;
  }

  .blue-border {
    border-left: 6px solid;
    border-color: #0071BC;
    padding-left: 20px;

    margin-top: 35px;
    @media screen and (max-width: 39.9375em) {
      margin-top: 20px;
    }
    margin-bottom: 35px;
    @media screen and (max-width: 39.9375em) {
      margin-bottom: 20px;
    }
    h1 {
      color: #0071BC;

    }
    ul {
      //color: #0071BC;
      li {
      }
    }

    .error{
      font-family: "acumin-pro-condensed", sans-serif;
      margin-top: -0.5rem;
      margin-bottom: 1rem;
      font-size: 0.75rem;
      font-weight: bold;
      color: #cc4b37;
    }
  }

  .white-border {
    border-left: 6px solid;
    border-color: #FFFFFF;
    padding-left: 20px;
    h1 {
      margin-top: 50px;
      color: #FFFFFF;
      @media screen and (max-width: 39.9375em) {
        margin-top: 20px;
      }
    }
    p {
      color: #FFFFFF;
      margin-bottom: 50px;
      @media screen and (max-width: 39.9375em) {
        margin-bottom: 20px;
      }
    }
    ul {
      color: #0071BC;
      li {
      }
    }
  }
}

// home page

.home-image {
  margin-top: 35px;
  margin-bottom: 30px;

  @media screen and (max-width: 39.9375em) {
    display: none;
  }
}

#slide1 {
  img {
    width: 100%;
  }
}

#slide2 {
  div {
    position: relative;
    .slide-text-overlay {
      background-color: #FFFFFF;
      position: absolute;
      top: 0;
      left: 30px;
      .padding {
        padding-left: 20px;
        padding-right: 20px;
        .blue-border {
          border-left: 6px solid;
          border-color: #0071BC;
          padding-left: 20px;

          margin-top: 20px;
          margin-bottom: 20px;

          h1 {
            color: #0071BC;
            margin-bottom: 0;

          }
          ul {
            //color: #0071BC;
            li {
            }
          }
        }
      }
    }
    img {
      width: 100%;
    }
  }

}

//team page

.team {

  h4 {
    @media screen and (max-width: 39.9375em) {
      margin-top: 20px;
    }
  }

  .team-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 150px;
    @media screen and (max-width: 39.9375em) {
      height: 200px;
    }
  }
}

//blog

@import 'components/blog';