// blog

.blog-background-image, .article-primary-image{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.article-primary-image{
  height: 400px;
  width: 100%;
  border-top: solid 1px $border-colour;
  @media screen and (max-width: 39.9375em) {
    height: 300px;
  }

}

.blog-box {
  position: relative;

  h1 {
    color: #FFFFFF;
    font-family: "acumin-pro-condensed", sans-serif;
    font-weight: 600;
    width: 90%;

  }

  p {
    color: #CCCCCC;
  }

  .box-padding{
    padding: 40px 20px 40px 20px;
    @media screen and (max-width: 63.9375em) {
      padding: 40px 10px 40px 10px;
    }
    @media screen and (max-width: 39.9375em) {
      padding: 20px 0 20px 0;
    }
  }

  .gradient {
    height: 100%;

    @media screen and (max-width: 39.9375em) {
    }
    .border-m{
      p{
        &:last-of-type{
          margin-bottom:0;
        }
      }
    }
  }

  .center-con {
    align-items: center;
    justify-content: center;
    width: 40px;
    position: absolute;
    top:10px;
    right: 10px;


    .round {
      position: relative;
      border: 3px solid transparent;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      span {
        display: block;
        position: absolute;
        z-index: 999;
        height: 3px;
        margin: 1px;
        width: 10px;
        background: #fff;
        transition: 0.2s ease;
        &:first-child {
          transform: rotate(0deg);
          left: 23%;
          bottom: 30%;
        }
        &:nth-child(2) {
          transform: rotate(-0deg);
          left: 40%;
          bottom: 30%;
        }
        &:nth-child(3) {
          transform: rotate(-0deg);
          left: 23%;
          bottom: 54%;
        }
        &:nth-child(4) {
          transform: rotate(0deg);
          left: 40%;
          bottom: 54%;
        }
      }

    }
  }

  &:hover {
    cursor: pointer;
    .center-con {
      .round {
        span {
          &:nth-child(1) {
            transform: rotate(45deg);
            bottom: 21%;
            width: 10px;
            left: 23%;
          }
          &:nth-child(2) {
            transform: rotate(-45deg);
            bottom: 21%;
            width: 10px;
            left: 40%;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            bottom: 67%;
          }
          &:nth-child(4) {
            transform: rotate(45deg);
            bottom: 67%;
          }
        }
      }
    }

  }

  &:nth-child(1) {
    border-bottom: solid 1px $border-colour;
    border-right: solid 1px $border-colour;
    border-top: solid 1px $border-colour;


    @media screen and (max-width: 63.9375em) {
      border-right: solid 0px $border-colour;

    }
    .gradient {
      background: rgb(37, 128, 195); /* Old browsers */
      background: -moz-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(27, 20, 100, 0.9) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(27, 20, 100, 0.9) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(37, 128, 195, 0.9) 0%, rgba(27, 20, 100, 0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .border-m {
      border-left: 6px solid;
      border-color: #1B1464;
      padding-left: 20px;
      background-color: transparent;

    }
  }

  &:nth-child(2) {
    border-bottom: solid 1px $border-colour;
    border-top: solid 1px $border-colour;
    @media screen and (max-width: 63.9375em) {
      border-top: solid 0px $border-colour;

    }
    .gradient {
      background: rgb(37, 128, 195); /* Old browsers */
      background: -moz-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(158, 0, 93, 0.9) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(158, 0, 93, 0.9) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(37, 128, 195, 0.9) 0%, rgba(158, 0, 93, 0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .border-m {
      border-left: 6px solid;
      border-color: #9E005D;
      padding-left: 20px;
      background-color: transparent;

    }
  }

  &:nth-child(3) {
    border-bottom: solid 1px $border-colour;
    border-right: solid 1px $border-colour;
    @media screen and (max-width: 63.9375em) {
      border-right: solid 0px $border-colour;

    }

    .gradient {
      background: rgb(37, 128, 195); /* Old browsers */
      background: -moz-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(0, 146, 69, 0.9) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(0, 146, 69, 0.9) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(37, 128, 195, 0.9) 0%, rgba(0, 146, 69, 0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .border-m {
      border-left: 6px solid;
      border-color: #8CC63F;
      padding-left: 20px;
      background-color: transparent;

    }
  }

  &:nth-child(4) {
    border-bottom: solid 1px $border-colour;
    .gradient {
      background: rgb(37, 128, 195); /* Old browsers */
      background: -moz-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(102, 45, 145, 0.9) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(102, 45, 145, 0.9) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(37, 128, 195, 0.9) 0%, rgba(102, 45, 145, 0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .border-m {
      border-left: 6px solid;
      border-color: #662D91;
      padding-left: 20px;
      background-color: transparent;

    }
  }

  &:nth-child(5) {
      border-bottom: solid 1px $border-colour;
      border-right: solid 1px $border-colour;
      @media screen and (max-width: 63.9375em) {
        border-right: solid 0px $border-colour;
      }


    .gradient {
      background: rgb(37, 128, 195); /* Old browsers */
      background: -moz-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(27, 20, 100, 0.9) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(27, 20, 100, 0.9) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(37, 128, 195, 0.9) 0%, rgba(27, 20, 100, 0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .border-m {
      border-left: 6px solid;
      border-color: #1B1464;
      padding-left: 20px;
      background-color: transparent;

    }
  }
  &:nth-child(6) {
    border-bottom: solid 1px $border-colour;
    .gradient {
      background: rgb(37, 128, 195); /* Old browsers */
      background: -moz-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(158, 0, 93, 0.9) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(158, 0, 93, 0.9) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(37, 128, 195, 0.9) 0%, rgba(158, 0, 93, 0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .border-m {
      border-left: 6px solid;
      border-color: #9E005D;
      padding-left: 20px;
      background-color: transparent;

    }
  }

  &:nth-child(7) {
    border-bottom: solid 1px $border-colour;
    border-right: solid 1px $border-colour;
    @media screen and (max-width: 63.9375em) {
      border-right: solid 0px $border-colour;

    }

    .gradient {
      background: rgb(37, 128, 195); /* Old browsers */
      background: -moz-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(0, 146, 69, 0.9) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(0, 146, 69, 0.9) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(37, 128, 195, 0.9) 0%, rgba(0, 146, 69, 0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .border-m {
      border-left: 6px solid;
      border-color: #8CC63F;
      padding-left: 20px;
      background-color: transparent;

    }
  }

  &:nth-child(8) {
    border-bottom: solid 1px $border-colour;
    .gradient {
      background: rgb(37, 128, 195); /* Old browsers */
      background: -moz-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(102, 45, 145, 0.9) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(37, 128, 195, 0.9) 0%, rgba(102, 45, 145, 0.9) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(37, 128, 195, 0.9) 0%, rgba(102, 45, 145, 0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .border-m {
      border-left: 6px solid;
      border-color: #662D91;
      padding-left: 20px;
      background-color: transparent;

    }
  }



}